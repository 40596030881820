
export type PossibleScriptNames = 
    | 'app'
    | 'initFileUserNotificationForm'
    | 'selectCompanyBranchWiredocScripts'
    | 'tps'
    | 'addFile'
    | 'multipleSelectScripts'
    | 'recipientsScripts'
    | 'documentWithRecipientsCheckbox'
    | 'viewFile';

window.ScriptRegistry = (function() {
const loadedScripts: Partial<Record<PossibleScriptNames, boolean>> = {};
function loadScript(name: PossibleScriptNames) {
    return new Promise<void>((resolve) => {
        if (loadedScripts[name]) {
            resolve();
        } else {
            document.addEventListener(name + '-loaded', () => resolve());
        }
    });
}

const knownScripts: Record<PossibleScriptNames, PossibleScriptNames> = {
    app: 'app',
    initFileUserNotificationForm: 'initFileUserNotificationForm',
    selectCompanyBranchWiredocScripts: 'selectCompanyBranchWiredocScripts',
    tps: 'tps',
    addFile: 'addFile',
    multipleSelectScripts: 'multipleSelectScripts',
    documentWithRecipientsCheckbox: 'documentWithRecipientsCheckbox',
    recipientsScripts: 'recipientsScripts',
    viewFile: 'viewFile'
};

return {
    knownScripts,
    register: function(name: PossibleScriptNames) {
        loadedScripts[name] = true;
        const event = new Event(name + '-loaded');
        document.dispatchEvent(event);
    },
    waitFor: function(scripts: PossibleScriptNames[], callback: () => void) {
        Promise.all(scripts.map(loadScript)).then(callback);
    }
};
})();

